// 导出数据
export const nodeToJson = (nodes) =>{
    const res = []

    nodes.forEach(node => {
        if(node.type != undefined){
            let point = node.businessObject
            if(node.type == 'myline')
            {
                if(node.source && node.target){
                    point.from = node.source.id;
                    point.to = node.target.id;
                    point.waypoint = node.waypoints.map(function(p) {
                        return { x: p.x, y: p.y };
                    })
                    res.push(point)
                }
            }
            else if(node.type != 'label'){
                point.x = node.x
                point.y = node.y

                res.push(point)
            }
            else{
                let mynode = res.find(t=>t.id == point.id)
                mynode.name =point.name ||''
                mynode.label = {
                    x:node.x,
                    y:node.y,
                    height:node.height,
                    width:node.width
                }
            }
        }
    })


    return res
}

// 导入数据
export const importData = (data,diagram)=>{
    const myImporter = diagram.get('myImporter')

    const nodes = data.filter(t=>t.type != 'myline')
    const lines = data.filter(t=>t.type == 'myline')

    nodes.forEach(item => {
        myImporter.add(item)
    })
    lines.forEach(item => {
        myImporter.add(item)
    })
}