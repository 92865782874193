import { getNewShapePosition } from './myAutoPlaceUtil';

export default function MyAutoPlace(eventBus) {
  eventBus.on('autoPlace', function(context) {
    var shape = context.shape,
        source = context.source;
    return getNewShapePosition(source, shape);
  });
}

MyAutoPlace.$inject = [ 'eventBus' ];