<template>
  <div ref="container" class="b-flow"></div>
</template>
<script>
/* eslint-disable */
import 'diagram-js/assets/diagram-js.css';

import Diagram from 'diagram-js';

import AlignElementsModule from 'diagram-js/lib/features/align-elements';
import BendpointsModule from 'diagram-js/lib/features/bendpoints';
import ConnectModule from 'diagram-js/lib/features/connect';
import ConnectionPreviewModule from 'diagram-js/lib/features/connection-preview';
import ContextPadModule from 'diagram-js/lib/features/context-pad';
import CreateModule from 'diagram-js/lib/features/create';
import HandToolModule from 'diagram-js/lib/features/hand-tool';
import LassoToolModule from 'diagram-js/lib/features/lasso-tool';
import GlobalConnectModule from 'diagram-js/lib/features/global-connect';
import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas';
import MoveModule from 'diagram-js/lib/features/move';
import OutlineModule from 'diagram-js/lib/features/outline';
import PaletteModule from 'diagram-js/lib/features/palette';
import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll';

/**自定义模块部分 */
import myModelingModule from './modules/modeling' ;
import myDraw from './modules/draw' ;
import myLabelEditing from './modules/labelEditing';
import mySnapping from './modules/snapping';
import myRulesModule from './modules/rules';
import myPalette from './modules/palette';
import myAutoPlace from './modules/autoPlace';
import myContextPad from './modules/contextPad';

import myImport from './modules/import';

import {nodeToJson,importData} from './myflow'


export default {
  name:'b-wflow',
  props: {

  },
  data () {
    return {
      scale: 1,
      diagram:null
    };
  },
  mounted () {
    this.$nextTick(()=>{
      this.init();
    })
  },
  computed:{
  },
  methods:{
    init(){
      var container = this.$refs.container;
      this.diagram = new Diagram({
        canvas: {
          container: container
        },
        modules: [
          
          BendpointsModule,
          ConnectModule,
          ConnectionPreviewModule,
          ContextPadModule,
          CreateModule,

          AlignElementsModule,
          HandToolModule,
          LassoToolModule,
          GlobalConnectModule,
          myModelingModule,
          MoveCanvasModule,
          MoveModule,
          OutlineModule,
          PaletteModule,
          ZoomScrollModule,


          myAutoPlace,
          myDraw,
          myLabelEditing,
          myContextPad,
          myPalette,
          myRulesModule,
          mySnapping,
          myImport,

        ]
      });
      var canvas = this.diagram.get('canvas'),
      elementFactory = this.diagram.get('elementFactory');

      // add root
      this.reset();
      var root = elementFactory.createRoot();
      canvas.setRootElement(root);
      this.initEvent();
      
    },
    // 绑定事件
    initEvent () {
      const eventBus = this.diagram.get('eventBus')
      // 双击事件
      eventBus.on('element.dblclick',e => {
        this.$emit('elementDblclick',e.element.businessObject);
      });
       // 点击事件
      eventBus.on('element.click', e => {
        this.$emit('elementClick',e.element.businessObject);
      })
      eventBus.on('create.end', e => {
        this.$emit('elementCreate',e.elements[0].businessObject);
      });
      eventBus.on('autoPlace.end', e => {
        this.$emit('elementCreate',e.shape.businessObject);
      });
    },
    reset(){
      this.scale = 1;
      this.diagram.get('canvas').zoom('fit-viewport');
    },
    handlerZoom (radio) {
      const newScale = !radio ? 1.0 : this.scale + radio
      this.diagram.get('canvas').zoom(newScale)
      this.scale = newScale
    },
    handlerRedo () {// 恢复
      this.diagram.get('commandStack').redo()
    },
    handlerUndo () {// 撤销
      this.diagram.get('commandStack').undo()
    },
    getData(){
      this.diagram.get('canvas').zoom(1)
      const all = this.diagram.get('elementRegistry').getAll()
      var res = nodeToJson(all);
      return res
    },
    setData(data){
      this.diagram.clear();
      this.reset();
      importData(data,this.diagram);
    },
    updateElemet(data){
      let myImporter = this.diagram.get('myImporter');
      myImporter.update(data.id,data);
    }
  }

}
</script>
<style lang="less">
@import './index.less';
</style>
