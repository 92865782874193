import {
  assign
} from 'min-dash';

export default function contextPadProvider(connect, contextPad, modeling,elementFactory,autoPlace,create) {
  this._connect = connect;
  this._modeling = modeling;
  this._elementFactory = elementFactory;
  this._autoPlace = autoPlace;
  this._create = create;

  contextPad.registerProvider(this);
}
  
contextPadProvider.$inject = [
  'connect',
  'contextPad',
  'modeling',
  'elementFactory',
  'autoPlace',
  'create'
];
  
contextPadProvider.prototype.getContextPadEntries = function(element) {
  var connect = this._connect,
      modeling = this._modeling,
      elementFactory = this._elementFactory,
      autoPlace = this._autoPlace,
      create = this._create;

  function removeElement() {
    modeling.removeElements([ element ]);
  }

  function startConnect(event, element, autoActivate) {
    connect.start(event, element, autoActivate);
  }

  function appendAction(type, className, title, options) {

    if (typeof title !== 'string') {
      options = title;
      //title = title;
    }

    function appendStart(event, element) {
      var shape = elementFactory.createShape(assign({ type: type }, options));
      create.start(event, shape, {
        source: element
      });
    }

    function append(event, element) {
      var shape = elementFactory.createShape(assign({ type: type }, options));
      autoPlace.append(element, shape);
    }

    return {
      group: 'model',
      className: className,
      title: title,
      action: {
        dragstart: appendStart,
        click: append
      }
    };
  }

  if(element.type == 'label'){
    return false;
  }

  if(element.type == 'myline' || element.type == 'endEvent'){
    return {
      'delete': {
        group: 'edit',
        className: 'icon-gateway6',
        title: 'Remove',
        action: {
          click: removeElement,
          dragstart: removeElement
        }
      },
    };
  }

  return {
    'create.end-event': appendAction(
      'endEvent', 'icon-end-event',
      '创建结束事件'
  ),
  
  'create.and-gateway': appendAction(
      'gatewayAnd','icon-gateway1',
      '并行网关'
  ),
  'create.xor-gateway': appendAction(
      'gatewayXor','icon-gateway2',
      '互排网关'
  ),
  'create.inclusive-gateway': appendAction(
      'gatewayInclusive','icon-gateway3',
      '包含网关'
  ),
  'create.task': appendAction(
      'userTask','icon-gateway4',
      '创建任务'
  ),
  'create.script': appendAction(
      'scriptTask','icon-gateway5',
      '创建脚本'
  ),
  'create.subprocess':appendAction(
      'subprocess','icon-subprocess',
      '创建子流程'
  ),
    'connect': {
      group: 'edit',
      className: 'icon-line-tool',
      title: '连线',
      action: {
        click: startConnect,
        dragstart: startConnect
      }
    },
    'delete': {
      group: 'edit',
      className: 'icon-gateway6',
      title: 'Remove',
      action: {
        click: removeElement,
        dragstart: removeElement
      }
    },
  };
};
