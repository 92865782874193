import {
  assign
} from 'min-dash';

import {
  getMid
} from 'diagram-js/lib/layout/LayoutUtil';

import { translate} from '@util/translate.js';

function elementData(semantic, attrs) {
  return assign({
    id: semantic.id,
    type: semantic.type,
    businessObject: semantic
  }, attrs);
}

function getWaypoints(node, source, target) {

  var waypoints = node.waypoint;
  if (!waypoints || waypoints.length < 2) {
    return [ getMid(source), getMid(target) ];
  }
  return waypoints.map(function(p) {
    return { x: p.x, y: p.y };
  });
}


export default function MyImporter(
    eventBus, canvas, elementFactory,
    elementRegistry, textRenderer) {
    this._eventBus = eventBus;
    this._canvas = canvas;
    this._elementFactory = elementFactory;
    this._elementRegistry = elementRegistry;
    this._translate = translate;
    this._textRenderer = textRenderer;
}

MyImporter.$inject = [
  'eventBus',
  'canvas',
  'elementFactory',
  'elementRegistry',
  'textRenderer'
];

MyImporter.prototype.add = function(node) {
  //let translate = this._translate;
  let element;
  let label = node.label;
  if(label){
    label.id = node.id;
    label.name = node.name;
    delete node.label;
  }

  // 添加线条
  if(node.type == 'myline'){
    var source = this._getSource(node),
    target = this._getTarget(node);
    element = this._elementFactory.createConnection(elementData(node, {
      source: source,
      target: target,
      waypoints: getWaypoints(node, source, target)
    }));

    this._canvas.addConnection(element);
  }
  // 添加节点
  else  {
    let wh = this._elementFactory._getDefaultSize(node.type);
    element = this._elementFactory.createShape(elementData(node, {
      x: Math.round(node.x),
      y: Math.round(node.y),
      width: wh.width,
      height:wh.height,
      isFrame: false
    }));
    this._canvas.addShape(element);
  }

  if(label && element){
    // 需要额外绘制文字
    this.addLabel(label,element);
  }

  return element;
};


MyImporter.prototype.addLabel = function(semantic, element) {
  let label = this._elementFactory.createLabel({
    id: semantic.id +'_label',
    labelTarget: element,
    type: 'label',
    x: Math.round(semantic.x),
    y: Math.round(semantic.y),
    width: Math.round(semantic.width),
    height: Math.round(semantic.height),
    businessObject:semantic
  });

  return this._canvas.addShape(label, element.parent);
};

MyImporter.prototype.update = function(id,data){
  let element = this._elementRegistry.get(id);
  element.businessObject = assign(element.businessObject,data);

  if(data.type == 'myline'){
    this._canvas.removeConnection(element);
    this._canvas.addConnection(element, element.parent);
  }
  else {
    this._canvas.removeShape(element);
    this._canvas.addShape(element, element.parent);
  }
  
  let label = this._elementRegistry.get(id + '_label');
  if(label){
    label.businessObject = element.businessObject;
    
    this._canvas.removeShape(label);
    this._canvas.addShape(label, element.parent);
  }
}


MyImporter.prototype._getSource = function(semantic) {
  return this._getElement(semantic.from);
};

MyImporter.prototype._getTarget = function(semantic) {
  return this._getElement(semantic.to);
};

MyImporter.prototype._getElement = function(id) {
  return this._elementRegistry.get(id);
};

