import {
    assign
} from 'min-dash';

export default function ExamplePaletteProvider(create, elementFactory,handTool, lassoTool, globalConnect, palette) {
    this._create = create;
    this._elementFactory = elementFactory;
    this._handTool = handTool;
    this._lassoTool = lassoTool;
    this._globalConnect = globalConnect;
    this._palette = palette;
  
    palette.registerProvider(this);
  }
  
  ExamplePaletteProvider.$inject = [
    'create',
    'elementFactory',
    'handTool',
    'lassoTool',
    'globalConnect',
    'palette'
  ];
  
  
  ExamplePaletteProvider.prototype.getPaletteEntries = function() {
    var create = this._create,
        elementFactory = this._elementFactory,
        handTool = this._handTool,
        lassoTool = this._lassoTool,
        globalConnect = this._globalConnect;
        
    function createAction(type, group, className, title, options) {

        function createListener(event) {
            var shape = elementFactory.createShape(assign({ type: type }, options));
            create.start(event, shape);
        }
        
        return {
            group: group,
            className: className,
            title: title,
            action: {
                dragstart: createListener,
                click: createListener
            }
        };
    }
        
  
    return {
        'hand-tool': {
            group: 'tools',
            className: 'icon-hand-tool',
            title: '激活手动工具',
            action: {
              click: function(event) {
                handTool.activateHand(event);
              }
            }
        },
        'lasso-tool': {
            group: 'tools',
            className: 'icon-lasso-tool',
            title: '激活套索工具',
            action: {
                click: function(event) {
                    lassoTool.activateSelection(event);
                }
            }
        },
        'global-connect-tool': {
            group: 'tools',
            className: 'icon-line-tool',
            title: '激活连线工具',
            action: {
              click: function(event) {
                globalConnect.toggle(event);
              }
            }
        },
        'tool-separator': {
            group: 'tools',
            separator: true
        },
        'create.start-event': createAction(
            'startEvent', 'event', 'icon-start-event',
            '创建开始事件'
        ),
        'create.end-event': createAction(
            'endEvent', 'event', 'icon-end-event',
            '创建结束事件'
        ),
        
        'create.and-gateway': createAction(
            'gatewayAnd', 'gateway', 'icon-gateway1',
            '并行网关'
        ),
        'create.xor-gateway': createAction(
            'gatewayXor', 'gateway', 'icon-gateway2',
            '互排网关'
        ),
        'create.inclusive-gateway': createAction(
            'gatewayInclusive', 'gateway', 'icon-gateway3',
            '包含网关'
        ),
        'create.task': createAction(
            'userTask', 'activity', 'icon-gateway4',
            '创建任务'
        ),
        'create.script': createAction(
            'scriptTask', 'activity', 'icon-gateway5',
            '创建脚本'
        ),
        'create.subprocess':createAction(
            'subprocess', 'activity', 'icon-subprocess',
            '创建子流程'
        )
    };
  };