import './styles/index.less'
import bWflow from './bWflow'
const prototypes = {
}

const components = {
  bWflow
}

const install = function (Vue) {
  if (install.installed) {
    return
  }

  Object.values(components).map(component => {
    Vue.use(component)
  })

  Object.keys(prototypes).forEach((key) => {
    Vue.prototype['$' + key] = prototypes[key];
  });
}

//  全局引用可自动安装
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  install,
  ...components
}

export {
  components
}
