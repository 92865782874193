/* eslint-disable */
import inherits from 'inherits';
import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';

import { isFrameElement } from 'diagram-js/lib/util/Elements';

export default function myRuleProvider(eventBus) {

  RuleProvider.call(this, eventBus);
  
}

myRuleProvider.$inject = [ 'eventBus' ];

inherits(myRuleProvider, RuleProvider);


myRuleProvider.prototype.init = function() {

  this.addRule('connection.start', function(context) {
    var source = context.source;
    if(source.type != '' && source.type != undefined && source.type != null){
      return true;
    }
    return false;
  });

  this.addRule('connection.create', function(context) {
    var source = context.source,
        target = context.target,
        hints = context.hints || {},
        targetParent = hints.targetParent,
        targetAttach = hints.targetAttach;

    // don't allow incoming connections on
    // newly created boundary events
    // to boundary events
    if (targetAttach) {
      return false;
    }

    // temporarily set target parent for scoping
    // checks to work
    if (targetParent) {
      target.parent = targetParent;
    }

    try {
      return canConnect(source, target);
    } finally {

      // unset temporary target parent
      if (targetParent) {
        target.parent = null;
      }
    }
  });

  this.addRule('connection.reconnect', function(context) {
    var connection = context.connection,
        source = context.source,
        target = context.target;
    return canConnect(source, target, connection);
  });

  this.addRule('connection.updateWaypoints', function(context) {
    return {
      type: context.connection.type
    };
  });


  this.addRule('shape.create', function(context) {
    var target = context.target,
        shape = context.shape;
    if(target.parent){
      target = parent;
    }

    if(shape.type == 'startEvent' && target.children.find(t => t.type == shape.type) != null){
      return false;
    }
    return true;
  });

  this.addRule('shape.resize', function(context) {
    var shape = context.shape;

    return isFrameElement(shape);
  });
};

myRuleProvider.prototype.canConnect = canConnect;


function canConnect(source, target, connection) {

  if(['startEvent','endEvent','gatewayAnd','gatewayXor','gatewayInclusive','gatewayInclusive','userTask','scriptTask','subprocess'].includes(target.type)
    && ['startEvent','gatewayAnd','gatewayXor','gatewayInclusive','gatewayInclusive','userTask','scriptTask','subprocess'].includes(source.type)
  ){

    if(source.id == target.id){
      return false;
    }
    
    return { type: 'myline' };
  }
  return false;
}