<template>
  <div ref="container" class="b-flow"></div>
</template>
<script>
/* eslint-disable */
import 'diagram-js/assets/diagram-js.css';

import Diagram from 'diagram-js';

import ConnectModule from 'diagram-js/lib/features/connect';
import CreateModule from 'diagram-js/lib/features/create';

import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas';

import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll';

/**自定义模块部分 */
import myModelingModule from './modules/modeling' ;
import myDraw from './modules/draw';




import myImport from './modules/import';

import {nodeToJson,importData} from './myflow'

export default {
  name:'b-wflow-viewer',
  props: {
  },
  data () {
    return {
      scale: 1,
      diagram:null
    };
  },
  mounted () {
    this.$nextTick(()=>{
      this.init();
    })
  },
  computed:{
  },
  methods:{
    init(){
      var container = this.$refs.container;
      
      this.diagram = new Diagram({
        canvas: {
          container: container
        },
        modules: [
          ConnectModule,
          CreateModule,
          myModelingModule,
          MoveCanvasModule,
          ZoomScrollModule,
          myDraw,
          myImport
        ]
      });
      var canvas = this.diagram.get('canvas'),
      elementFactory = this.diagram.get('elementFactory');

      // add root
      this.reset();
      var root = elementFactory.createRoot();
      canvas.setRootElement(root);

      this.initEvent();
    },
        // 绑定事件
    initEvent () {
      const eventBus = this.diagram.get('eventBus')
      
      // 双击事件
      eventBus.on('element.dblclick',e => {
        this.$emit('elementDblclick',e.element.businessObject);
      });
       // 点击事件
      eventBus.on('element.click', e => {
        this.$emit('elementClick',e.element.businessObject);
      });
    },
    reset(){
      this.scale = 1;
      this.diagram.get('canvas').zoom('fit-viewport');
    },
    handlerZoom (radio) {
      const newScale = !radio ? 1.0 : this.scale + radio
      this.diagram.get('canvas').zoom(newScale)
      this.scale = newScale
    },
    getData(){
      const all = this.diagram.get('elementRegistry').getAll()
      var res = nodeToJson(all);
      return res
    },
    setData(data){
      this.diagram.clear();
      this.reset();
      importData(data,this.diagram);
    },
    updateElemet(data){
      let myImporter = this.diagram.get('myImporter');
      myImporter.update(data.id,data);
    }
  }

}
</script>
<style lang="less">
@import './index.less';
</style>
