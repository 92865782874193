/**
 * 重载diagram-js 的ElementFactory 方法
 */
import { translate} from '@util/translate.js';
import { uuid} from '@util/util.js';
import {
    assign
} from 'min-dash';
import inherits from 'inherits';
import BaseElementFactory from 'diagram-js/lib/core/ElementFactory';

import {
    DEFAULT_LABEL_SIZE
} from '../component/label';


export default function ElementFactory() {//
    BaseElementFactory.call(this);
    this._translate = translate;
}

inherits(ElementFactory, BaseElementFactory);

ElementFactory.$inject = [
];

ElementFactory.prototype.baseCreate = BaseElementFactory.prototype.create;



ElementFactory.prototype.create = function(elementType, attrs) {
    if (elementType === 'label') {
      return this.baseCreate(elementType, assign({ type: 'label' }, DEFAULT_LABEL_SIZE, attrs));
    }
    return this.createFlowElement(elementType, attrs);
};

ElementFactory.prototype.createFlowElement = function(elementType, attrs) {
    if(attrs == null || attrs == undefined || attrs == '' ){
        return this.baseCreate(elementType, attrs);
    }

    attrs.businessObject = attrs.businessObject || {
        id:uuid()
    };
    

    if (elementType === 'connection') {
        attrs.type = 'myline';
    }

    var businessObject = attrs.businessObject;
    businessObject.type = attrs.type;

    var size = this._getDefaultSize(businessObject.type);

    attrs = assign({
        businessObject: businessObject,
        id: businessObject.id
    }, size, attrs);

    return this.baseCreate(elementType, attrs);
};


ElementFactory.prototype._getDefaultSize = function(type) {
    var size = { width: 100, height: 80 };
    switch(type){
        case 'startEvent':
        case 'endEvent':
            size = { width: 36, height: 36 };
            break;
        case 'gatewayAnd':
        case 'gatewayXor':
        case 'gatewayInclusive':
            size =  { width: 50, height: 50 };
            break;
        case 'subprocess':
        case 'userTask':
        case 'scriptTask':
            size = { width: 100, height: 80 };
            break;
    }
    return size;
};




