
import CommandModule from 'diagram-js/lib/command';
import LabelSupportModule from 'diagram-js/lib/features/label-support';
import SelectionModule from 'diagram-js/lib/features/selection';
import ElementFactory from './elementFactory';
import Modeling from './modeling';

import ElementUpdater from './elementUpdater';
import ElementLayouter from './elementLayouter';
import CroppingConnectionDocking from 'diagram-js/lib/layout/CroppingConnectionDocking';
export default {
  __depends__: [
    CommandModule,
    LabelSupportModule,
    SelectionModule
  ],
  __init__: [
    'modeling',
    'elementUpdater'
  ],
  modeling: [ 'type', Modeling ],
  layouter: [ 'type', ElementLayouter ],
  elementUpdater:['type',ElementUpdater],
  elementFactory: [ 'type', ElementFactory ],
  connectionDocking: [ 'type', CroppingConnectionDocking ]
};