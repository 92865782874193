
  
  import {
    componentsToPath
  } from 'diagram-js/lib/util/RenderUtil';
  
  
  // element utils //////////////////////

  
  export function getSemantic(element) {
    return element.businessObject;
  }
  
  
  // color access //////////////////////
  
  export function getFillColor(element, defaultColor) {//element, defaultColor
    return element.businessObject.fillColor || defaultColor || 'white';
  }
  
  export function getStrokeColor(element, defaultColor) {//element, defaultColor
    return element.businessObject.strokeColor || defaultColor || 'black';
  }
  
  // cropping path customizations //////////////////////
  
  export function getCirclePath(shape) {
  
    var cx = shape.x + shape.width / 2,
        cy = shape.y + shape.height / 2,
        radius = shape.width / 2;
  
    var circlePath = [
      ['M', cx, cy],
      ['m', 0, -radius],
      ['a', radius, radius, 0, 1, 1, 0, 2 * radius],
      ['a', radius, radius, 0, 1, 1, 0, -2 * radius],
      ['z']
    ];
  
    return componentsToPath(circlePath);
  }
  
  export function getRoundRectPath(shape, borderRadius) {
  
    var x = shape.x,
        y = shape.y,
        width = shape.width,
        height = shape.height;
  
    var roundRectPath = [
      ['M', x + borderRadius, y],
      ['l', width - borderRadius * 2, 0],
      ['a', borderRadius, borderRadius, 0, 0, 1, borderRadius, borderRadius],
      ['l', 0, height - borderRadius * 2],
      ['a', borderRadius, borderRadius, 0, 0, 1, -borderRadius, borderRadius],
      ['l', borderRadius * 2 - width, 0],
      ['a', borderRadius, borderRadius, 0, 0, 1, -borderRadius, -borderRadius],
      ['l', 0, borderRadius * 2 - height],
      ['a', borderRadius, borderRadius, 0, 0, 1, borderRadius, -borderRadius],
      ['z']
    ];
  
    return componentsToPath(roundRectPath);
  }
  
  export function getDiamondPath(shape) {
  
    var width = shape.width,
        height = shape.height,
        x = shape.x,
        y = shape.y,
        halfWidth = width / 2,
        halfHeight = height / 2;
  
    var diamondPath = [
      ['M', x + halfWidth, y],
      ['l', halfWidth, halfHeight],
      ['l', -halfWidth, halfHeight],
      ['l', -halfWidth, -halfHeight],
      ['z']
    ];
  
    return componentsToPath(diamondPath);
  }
  
  export function getRectPath(shape) {
    var x = shape.x,
        y = shape.y,
        width = shape.width,
        height = shape.height;
  
    var rectPath = [
      ['M', x, y],
      ['l', width, 0],
      ['l', 0, height],
      ['l', -width, 0],
      ['z']
    ];
  
    return componentsToPath(rectPath);
  }